<template>
  <popup-modal ref="popup">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="_cancel"> 
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <slot name="header"> {{UserType}} Authorization Required </slot>
            <div class="close-wrapper" @click="_cancel">
              <svg class="icon">
                <use xlink:href="#close"></use>
              </svg>
            </div>
          </div>
          <div class="modal-body">
            <form action="#" @submit.prevent="_confirm">
              <div>
                <label >{{ responseText }}</label>
                <div class="btn-align">
                <button class="main-btn">OK</button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div> 
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data() {
    return {
   responseText: "",
   UserType: ""
    };
  },
  
  computed: {
   
  },
  methods: {
   
    show(opts = {}) {
    this.responseText = opts.responseText;
    this.UserType = opts.UserType;
    this.$refs.popup.open();
    },

    _confirm() {
      this.$refs.popup.close();
    },

    _cancel() {
      this.$refs.popup.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-btn {
  padding: 0.5em 1em;
  background-color: #5858c7;
  color: white;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 35px 30px;
  background-color: #d7d7d7;
  border-radius: 10px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.modal-header {
  margin-top: 0;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.modal-body {
  margin: 10px 0;
  label {
    display: inline-block;
    margin-bottom: 5px;
    color: #3c3c3c;
  }
  .btn-align {
    text-align: center;
    margin-top: 20px;
  }
}

.cancel-btn:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.33);
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.close-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
}

@media (min-width: 992px) {
  .modal-container {
    width: 500px;
  }
}
</style>
